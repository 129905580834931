import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormDialogDefaultRenderer, } from "components-care/dist/backend-components/Form/FormDialog";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Modal, styled } from "@mui/material";
import { useIsMobile } from "../utils";
import { FormContextLite, FormRenderAsDivContext, UnsetFormContext, } from "components-care";
import { createPortal } from "react-dom";
import LayoutPortal, { useLayoutPortalElement, } from "../components/LayoutPortal";
const MobileActivity = styled(Modal)(({ theme }) => ({
    position: "fixed",
    top: 56,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1300,
    display: "flex",
    flexDirection: "column",
}));
export const SamedisFullFormDialogContext = React.createContext(null);
const SamedisFullFormDialogMobileActivity = (props) => {
    const { children, onClose } = props;
    // position the mobile activity to fit the header
    const mobileActivityRef = useRef(null);
    useEffect(() => {
        const activity = mobileActivityRef.current;
        if (!activity)
            return;
        const positionMobileActivity = () => {
            if (!activity)
                return;
            const header = document.getElementById("SamedisHeader");
            activity.style.top = (header ? header.clientHeight : 0) + "px";
        };
        positionMobileActivity();
        window.addEventListener("resize", positionMobileActivity);
        return () => {
            window.removeEventListener("resize", positionMobileActivity);
        };
    }, []);
    return (_jsx(MobileActivity, { ref: mobileActivityRef, onClose: onClose, hideBackdrop: true, open: true, children: children }));
};
const CustomStylesWrapper = styled("div")({
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
});
const SamedisFullFormDialog = (props) => {
    const { useCustomClasses, dialogTitle, children, className, onClose } = props;
    const isMobile = useIsMobile();
    const hasFormContext = !!useContext(FormContextLite);
    const context = useMemo(() => ({
        useFullHeight: !!useCustomClasses,
        dialogTitle,
    }), [useCustomClasses, dialogTitle]);
    const portalElem = useLayoutPortalElement();
    const portalContent = useCustomClasses ? (_jsx(UnsetFormContext, { children: _jsx(FormRenderAsDivContext.Provider, { value: hasFormContext, children: children }) })) : (children);
    const layoutContent = (() => {
        if (isMobile && useCustomClasses) {
            return (_jsx(SamedisFullFormDialogMobileActivity, { onClose: onClose, children: _jsx(CustomStylesWrapper, { className: className, children: _jsx(LayoutPortal, { elem: portalElem }) }) }));
        }
        return (_jsx(FormDialogDefaultRenderer, { ...props, children: _jsx(LayoutPortal, { elem: portalElem }) }));
    })();
    return (_jsxs(SamedisFullFormDialogContext.Provider, { value: context, children: [layoutContent, createPortal(portalContent, portalElem)] }));
};
export default React.memo(SamedisFullFormDialog);
