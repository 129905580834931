import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo, useRef, useState, } from "react";
import { combineClassNames } from "components-care";
import { styled } from "@mui/material";
import useIsMobile from "../utils/useIsMobile";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import { IS_DEV } from "../constants";
const Root = styled("div", { name: "SamedisFormPageLayout", slot: "root" })({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
});
const Wrapper = styled("div", {
    name: "SamedisFormPageLayout",
    slot: "wrapper",
})({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
});
const Body = styled("div", { name: "SamedisFormPageLayout", slot: "body" })({
    paddingBottom: 90,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    ["&.SC-AltButtonsRendered"]: {
        paddingBottom: 0,
    },
});
const Footer = styled("div", { name: "SamedisFormPageLayout", slot: "footer" })(({ theme }) => ({
    position: "absolute",
    padding: 0,
    bottom: theme.spacing(1),
    transform: "unset",
    width: "100%",
    left: 0,
    zIndex: 10,
}));
export const SamedisFormPageLayoutContext = React.createContext(undefined);
const SamedisFormPageLayout = (props) => {
    const { body, footer, other, className, classes } = props;
    const isMobile = useIsMobile();
    const inDialog = useContext(IsInFormDialogContext);
    const altButtons = isMobile || inDialog;
    const [altButtonsRendered, setAltButtonsRendered] = useState(0);
    const altButtonsRenderedRef = useRef(altButtonsRendered);
    altButtonsRenderedRef.current = altButtonsRendered;
    const ctx = useMemo(() => ({
        altButtons: altButtons ? footer : null,
        altButtonsDialog: inDialog && !isMobile,
        setAltButtonsRendered,
    }), [altButtons, footer, inDialog, isMobile]);
    if (IS_DEV) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            const timer = window.setTimeout(() => {
                const expected = altButtons ? 1 : 0;
                if (altButtonsRenderedRef.current !== expected) {
                    throw new Error(`SamedisFormPageLayout: altButtonsRendered (${altButtonsRenderedRef.current}) != ${expected}`);
                }
            }, 1000);
            return () => {
                window.clearTimeout(timer);
            };
        }, [altButtons]);
    }
    return (_jsx(SamedisFormPageLayoutContext.Provider, { value: ctx, children: _jsxs(Root, { className: combineClassNames([className, classes?.root]), children: [_jsxs(Wrapper, { className: classes?.wrapper, children: [_jsx(Body, { className: combineClassNames([
                                altButtonsRendered > 0 && "SC-AltButtonsRendered",
                                classes?.body,
                            ]), children: body }), altButtonsRendered === 0 && (_jsx(Footer, { className: classes?.footer, children: footer }))] }), other] }) }));
};
export default React.memo(SamedisFormPageLayout);
