import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef } from "react";
import { styled } from "@mui/material";
import { useRefComposer } from "react-ref-composer";
export const useLayoutPortalElement = () => useMemo(() => {
    const elem = document.createElement("div");
    elem.style.display = "flex";
    elem.style.flexGrow = "1";
    elem.style.flexDirection = "column";
    elem.style.minHeight = "0";
    return elem;
}, []);
const PortalContainer = styled("div")({
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    maxHeight: "100%",
});
/**
 * if you're wondering what this portal stuff is used for: it's for maintaining the state of the children
 * normally we would reset the state of children because we change the render tree (e.g. FormDialog > Mobile/Desktop Layout > Children)
 * this works around that fact by moving DOM nodes around. quite hacky, but seems to work fine.
 */
const LayoutPortal = React.forwardRef(function LayoutPortal(props, ref) {
    const { elem } = props;
    const composeRefs = useRefComposer();
    const myRef = useRef(null);
    useEffect(() => {
        myRef.current.appendChild(elem);
        window.dispatchEvent(new UIEvent("resize", { bubbles: true })); // trigger size recalculations
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return _jsx(PortalContainer, { ref: composeRefs(myRef, ref) });
});
export default React.memo(LayoutPortal);
